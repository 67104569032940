#StudioWrapper {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
}

#StudioWrapper #BigPic {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.column {
  flex: 25%;
  max-width: 33%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

@media screen and (min-width: 1920px) {
  #StudioWrapper #BigPic {
    margin-top: -10%;
  }
}

@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }

  #CenterLogo {
    width: 15% !important;
    top: 50px !important;
  }
}

@media screen and (max-width: 600px) {
  .column {
    flex: 40%;
    max-width: 48%;
  }
}

#StudioSection {
  position: relative;
}

#StudioWrapper {
  /* position: relative; */
}

#BigPic {
  /* position: relative; */
  width: 100%;
}

.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

/* FOR THE FIRST COLUMN */
#StudioPic17-768,
#StudioPic16-768,
#StudioPic13-768,
#StudioPic2-768 {
  display: none;
}

/* FOR THE SECOND COLUMN */
#StudioPic12-768,
#StudioPic6-768 {
  display: none;
}

@media (max-width: 768px) {

  /* FOR THE FIRST COLUMN */
  #StudioPic17-768,
  #StudioPic16-768,
  #StudioPic13-768,
  #StudioPic2-768 {
    display: block;
  }

  /* FOR THE SECOND COLUMN */
  #StudioPic12-768,
  #StudioPic6-768 {
    display: block;
  }

  /* FOR THE THIRD COLUMN */
  .StudioPic-thirdColumn {
    display: none;
  }
}