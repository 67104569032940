.video-wrapper {
    background-color: #121212;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .video-player {
    height: auto;
    min-width: calc(600px *(9/16));
    max-height: 600px;
    aspect-ratio: 9/16;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
  
  .loading {
    font-size: 18px;
  }
  
  .controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  .download-button {
    background-color: #ff2e63 !important;
  }
  .download-button:hover {
    opacity: .5;
    transition: .3s;
  }
  .nav-button {
    color: white !important;
  }
  
  .thumbnails {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 15px;
    overflow-x:scroll;
  }
  
  .thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
    border: 2px solid transparent;
  }
  
  .thumbnail.active {
    border-color: #ff2e63;
  }
  
@media only screen and (max-width: 768px) {
  .thumbnails {
    width: 100%;
    overflow-x: scroll;
    gap: 5px;
    display: none;
  }
  
  .video-player {
    height: auto;
    min-width: calc(600px *(9/16));
    max-height: 83.33svh;
  }
   
  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100svh;
    text-align: center;
    padding-top: 5svh;
  }
  
}
