#LinkURL {}

.linkbuttonwrap {}

#LinkButton {
    border: solid 1px;
    border-color: #0b4252;
    background-color: white;
    text-decoration: none;
    height: 5.5svh;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 9998;
    position: absolute;
    top: 85%;
    right: 40%;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}


#LinkButton2 {
    /* background-color: rgba(0, 255, 255, 1); */
    border: solid 1px;
    border-color: #0b4252;
    background-color: white;
    text-decoration: none;
    height: 5.5svh;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 9998;
    position: absolute;
    top: 85%;
    right: 17.5%;
    box-shadow: rgb(204, 219, 232) 1px 1px 2px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

#LinkButton3 {
    border: solid 1px;
    border-color: #0b4252;
    background-color: white;
    text-decoration: none;
    height: 5.5svh;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 9998;
    position: absolute;
    top: 85%;
    left: 17.5%;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

#NewLink:hover {
    filter: grayscale(.5);
    transition: .6s !important;
}

#LinkButton:hover {
    opacity: .675;
    transition: .6s !important;
}

#LinkButton2:hover {
    opacity: .675;
    transition: .6s !important;
}

#LinkButton3:hover {
    opacity: .675;
    transition: .6s !important;
}

#LinkButtonText {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 2.75svh;
    letter-spacing: -0.25px;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    #LinkButton {
        position: absolute;
        top: 82.5%;
        left: 20%;
        width: 60%;
    }

    #LinkButtonText {
        font-size: 18px;
    }

    #LinkButton2 {
        position: absolute;
        top: 90%;
        left: 20%;
        width: 60%;
    }

    #LinkButton3 {
        position: absolute;
        top: 75%;
        left: 20%;
        width: 60%;
    }

}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
    #LinkButton {
        position: absolute;
        top: 82.5%;
        left: 20%;
        width: 60%;
    }

    #LinkButtonText {
        font-size: 18px;
    }

    #LinkButton2 {
        position: absolute;
        top: 90%;
        left: 30%;
        width: 80%;
    }

    #LinkButton3 {
        position: absolute;
        top: 72.5%;
        left: 10%;
        width: 80%;
    }

}

@media only screen and (max-width: 768px) {
    #LinkButton {
        position: absolute;
        top: 92.5%;
        left: 10%;
        width: 80%;
        font-size: 16px;
    }

    #LinkButton3 {
        position: absolute;
        top: 77.5%;
        left: 10%;
        width: 80%;
        font-size: 16px;
    }

    #LinkButton2 {
        position: absolute;
        top: 85%;
        left: 10%;
        width: 80%;
        font-size: 16px;
    }
}