#AboutWrapper {}

#AboutPage {
    background-color: black;
    margin: 0;
    padding-top: 10svh;
    padding-left: 1vh;
    padding-right: 1vh;
    overflow: hidden;
    position: relative;
    height: 92.5svh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

@font-face {
    font-family: 'CustomFont';
    src: url('../assets/UTILITY.ttf') format('truetype');
}

#AboutPage video {
    position: fixed;
    width: 35%;
    top: 40%;
    transform: translateY(-50%);
    right: 0;
    transition: right 0.3s linear;
    /* Smoother transition */
    z-index: 2;
}

#AboutSections {
    position: relative;
    z-index: 0;
}

#AboutSections img {
    width: 40%;
    padding: 1%;
}

#AboutSections #PartSection {
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-left: 6.25%;
    padding-right: 6.25%;
    padding-bottom: 7.5%;

}

#AboutSections #PartSection h4 {
    color: white;
    padding: 5%;
    font-size: 4vh;
    font-weight: 400;
    line-height: 5vh;
    text-align: justify;
}

#SmallAbout {
    /* display: none; */
}


.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    z-index: 9999; /* Ensure it is above all other content */
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .loading-text {
    color: white;
    font-weight: 300;
    font-size: 1.5em;
  }
  

@media only screen and (min-width: 1920px) {
    #AboutPage {
        height: 100svh;
    }
    
    #AboutPage video {
        scale: .975;
    }
    #AboutSections img {
        width: 50%;
    }
    
    #AboutSections #PartSection h4 {
        color: white;
        padding: 2.5%;
        padding-right: 7.5%;
        padding-left: 7.5%;
    }

}

@media only screen and (max-width: 1200px) {
    #AboutSections #PartSection h4 {
        font-size: 4.5vh;
        font-weight: 400;
        line-height: 5.5vh;
        text-align: left;
    }
}


@media only screen and (max-width: 1024px) {
    #AboutSections #PartSection h4 {
        font-size: 2.2svh;
        font-weight: 400;
        line-height: 2.88svh;
        padding: 1%;
    }
    #AboutSections img {
        width: 50%;
    }
}

  @media only screen and (max-width: 768px) {
    #AboutPage {
        display: none;
    }
    #AboutSections img {
        width: 50%;
    }
    
    #AboutSections #PartSection h4 {
        color: white;
        padding: 2.5%;
        font-size: 1.5vh;
        font-weight: 400;
        line-height: 2vh;
        text-align: left;
        padding-top: 7.5%;
    }
}