.navbar {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 0px;
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 1000;
  -webkit-user-select: none;
  user-select: none;
  max-width: 2560px;
  /* background-color: green; */
}

.navbar-logo img {
  height: 120px; /* Adjust for better appearance */
  margin-top: 2.5%;
}

.navbar-logo {
  margin-left: 5%;
}

#MiniLogoArea:hover{
  opacity: .75;
  transition: .4s ease-in;
}
.navbar-links {
  list-style: none;
  display: flex;
  gap: 10%;
  margin-right: 12.25%;
  letter-spacing: -2px;
  transition: margin-top 0.3s ease-out;
}

.navbar-links li {
  margin: 0;
  position: relative; /* For positioning the dropdown */
}

.navbar-links a, .navbar-links span {
  font-weight: 500;
  color: white;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  /* user-select: none; Prevent text selection */

}

#upcomingnav a, #upcomingnav span  {
  color: white;
}

.navbar-links a:hover, .navbar-links span:hover {
  text-decoration: underline;
}

.dropdown-toggle {
  display: inline-flex;
  align-items: center;
}

.dropdown-icon {
  margin-left: 8px; /* Increase distance between text and icon */
}

.dropdown-menu {
  list-style: none;
  position: absolute;
  top: 50%; /* Position below the link */
  left: 0;
  background-color: #cbb8a5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  z-index: 1001;
  width:100%;
  padding: 5%;
  padding-top: 7.5%;
  padding-bottom: 7.5%;
  border-radius: 20px;
}

#dropdown-menu {
  width:100%;
}

.dropdown-menu.show {
  max-height: 200px; /* Adjust based on content height */
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu li {
  margin: 0;
}

.dropdown-menu a {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: black;
  text-decoration: none;
  font-size: 20px;
  width: 100%;
  letter-spacing: -1px;
}

.dropdown-menu a:hover {

}

@media only screen and (min-width: 1920px) {
  .navbar-logo img {
    height: 10svh; /* Adjust for better appearance */
  }
}
