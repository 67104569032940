#LivePicturesWrap {
  overflow-x: auto;
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
  display: flex;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  /* For Firefox */
  scroll-behavior: smooth;
  position: relative;
  cursor: grab;
}

#LivePicturesWrap:active {
  cursor: grabbing;
}

#LivePicturesWrap::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.section {
  min-width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
  scroll-snap-align: start;
}

.section .imgsection {
  width: 100svw;
}

.section .word {
  font-size: 15vh;
  text-align: center;
  margin: 0;
  position: relative;
  display: inline-block;
  opacity: 1;
  margin-right: 2.5%;
  font-weight: 600;
  letter-spacing: -3px;
  -webkit-text-stroke: 6px black;
  -webkit-user-select: none;
  user-select: none;
}

.section:nth-child(n+2) {
  background-size: cover;
  background-position: center;
}

.next-image-overlay {
  position: absolute;
  top: 20%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px 0 0 10px;
  padding: 10px;
  cursor: pointer;
}

.next-image-preview {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  margin-right: 10px;
  border: 2px solid black;
}

.next-image-text {
  font-size: 16px;
  font-weight: bold;
}

.arrow-buttons {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.arrow {
  background: rgba(255, 255, 255, 1);
  border: none;
  font-size: 24px;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.thumbnail-wrapper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5px;
}

.thumbnail {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease, border 0.3s ease;
  transform: scale(.75);
}

.thumbnail.active {
  transform: scale(1);
  border-color: white;
}


@media (max-width: 1024px) {
  .section .word {
    font-size: 6.75svh;
    letter-spacing: 0;
    -webkit-text-stroke: 3px black;
  }

}


@media (max-width: 768px) {
  .thumbnail-wrapper {
    display: none;
  }
  .section .imgsection {
    opacity: 0;
  }
  .section .word {
    font-size: 5svh;
    letter-spacing: 0;
    -webkit-text-stroke: 1px black;
  }

  .next-image-text {
    font-size: 12px;
    font-weight: bold;
  }

  .next-image-preview {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
    border: 2px solid black;
  }

  .arrow {
    font-size: 18px;
    padding: 5px;
  }
}