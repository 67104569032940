.PressWrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: black;
  color: #ffffff;
  min-height: 100svh;
  background: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 1)), url('../assets/logo.png');
  background-size: contain;
  overflow-x: hidden;
  box-sizing: border-box;
}

.press-container {
  max-width: 800px;
  height: 350px;
  width: 48.75%;
  background-color: white;
  /* padding: 20px; */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1%;
}


.page-title {
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffffff;
  padding: 2.5% 0%;
}

.description {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #1e1e1e;
  padding: 0;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 50%;
  align-self: center;
}

.linkgrid {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.21);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
}

.linkicon {
  background-color: black;
  color: white;
  border-radius: 50%;
  padding: 1%;
  display: flex;
  justify-content: center;
  margin: 1%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  z-index: 10;
}

#musiclinkicon {
  height: 30px;
  align-items: center;
  width: 30px;
}

.linkicon img {
  height: 24px;
}

.linkicon:hover {
  background-color: white;
}

.linkslayout {
  display: flex;
  align-items: center;
  justify-content: center;
}

.press-link-button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.press-link-button:hover {
  background-color: #333333;
  border-color: #b0b0b0;
}

.linkslayout {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.linkslayout.open {
  max-height: 200px;
}

.articlecoverage {
  min-width: 200px;
  width: 200px;
  height: 300px;
  border-radius: 10px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  transition: all 0.3s ease;
}

.article-text {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.article-details {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  color: white;
  overflow: auto;
  border-radius: 10px;
}

.read-more {
  color: #FF5733;
  text-decoration: none;
}


.articlecoverage:hover {
  min-width: 350px;
}

#musiccoverage:hover {
  min-width: 350px;

}

.wrapdata {
  display: flex;
  gap: 2.5%;
  overflow-x: auto;
}

#customid_rest {
  background-position: 10% 10%;
}

.originals {
  display: flex;
  gap: 2.5%;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .press-container {
    max-width: 800px;
    height: 200px;
    width: 100%;
    margin-bottom: 5%;
  }

}

@media only screen and (max-width: 768px) {
  .articlecoverage {
    min-width: 200px;
  }

  .PressWrapper {
    padding: 60px 15px;
  }

  .press-container {
    max-width: 800px;
    height: 135px;
    width: 92.5%;
    align-self: center;
    margin-bottom: 7.5%;
  }

  .originals {
    flex-direction: column;
  }

  .page-title {
    margin-bottom: 10px;
    font-weight: bold;
    color: #ffffff;
    padding: 5% 0%;
  }

}