/* NotFound CSS */
.NotFound {
    display: flex;
    flex-direction: column;
    height: 100svh;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.NotFound .returnhome {
    text-decoration: none;
    font-size: 16px;
    background-color: rgb(37, 143, 143);
    padding: 1% 2% 1% 2%;
    border-radius: 20px;
    color: white;
    font-weight: bold;
}

.NotFound .returnhome:hover{
    padding: 1.25% 2.5% 1.25% 2.5%;
    transition: .6s ease-in;
}