footer {
  background: black;
  color: #fff;
  border-style: solid;
  border-width: 1px;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom: 0;
  border-color: #1e1e1e;
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

footer h3 {
  font-size: 1.75svh;
  font-weight: 600;
  user-select: none;
}

#leftside, #rightside {
  display: flex;
  flex-direction: row;
  width: 25%;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

#rightside {
  justify-content: flex-end;
}

.social-icon {
  color: white;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #00aced; 
  scale: 1.2;
  transition: .4s;
}

@media only screen and (min-width: 2560px) {
  footer{
    width: 100%;
    padding-bottom: 2.5rem;
  }
  footer h3 {
    font-size: 1.5svh;
    font-weight: 600;
    user-select: none;
  }
}

@media only screen and (max-width: 1024px) {
  footer{
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #leftside, #rightside {
    width: 100%;
  }

  footer h3 {
    font-size: 1.25svh;
    font-weight: 500;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  
}

@media only screen and (max-width: 768px) {
  footer{
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #leftside, #rightside {
    width: 100%;
  }

  footer h3 {
    font-size: 1svh;
    font-weight: 500;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  
}
