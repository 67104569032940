.HamburgerNav {
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 9999;
}

.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 0;
  /* Align to the left */
}

.hamburger .bar {
  width: 30px;
  height: 5px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.4s;
  border-radius: 10px;
}

.hamburger.live-pictures .bar,
.hamburger.releases .bar {
  background-color: #000;
}

.hamburger.open .bar {
  background-color: transparent;
}

.hamburger.open .bar::before {
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: #fff;
  transform: rotate(45deg);
  transition: 0.4s;
}

.hamburger.open .bar::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: #fff;
  transform: rotate(-45deg);
  transition: 0.4s;
}

.hamburger.open.releases .bar::before,
.hamburger.open.releases .bar::after,
.hamburger.open.live-pictures .bar::before,
.hamburger.open.live-pictures .bar::after {
  background-color: #000;
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.menu {
  position: fixed;
  top: 60px;
  left: -100%;
  height: 80%;
  width: 200px;
  background-color: black;
  display: flex;
  flex-direction: column;
  transition: 0.1s ease-in-out;
  z-index: 1000;
  /* Ensure the menu is on top */
  padding: 30px;
  border-radius: 20px;
  padding-top: 50px;
}

.menu.open {
  left: 50px;
}

.close-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 32px;
  cursor: pointer;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 900;
  user-select: none; /* Prevents text selection */
  -webkit-tap-highlight-color: transparent; /* Removes default highlight on tap for mobile */

}

.close-icon.black {
  color: #000;
  /* Black color for specific pages */
}

.menu a {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  margin: 15px 0;
  text-transform: uppercase;
  transition: color 0.3s ease;
  font-weight: 900;
}

.menu a:hover {
  color: #ababab !important;
  scale: 1.1;
  transition: .4s ease-in-out;
}


@media (max-width: 1000px) {
  .menu.open {
    left: 0px;
  }
}

@media (max-width: 600px) {
  .menu {
    top: 0;
    height: 80%;
    width: 60%;
    margin: 5px 0;
    border-top-left-radius: 0;
    padding: 4vh;
    padding-bottom: 4vh;
    padding-top: 12vh;
  }

  .close-icon {
    z-index: 9999;
  }

  .menu a {
    font-size: 3vh;
    margin: 5;
    margin-right: 0 !important;
    margin-left: 0;
    padding: 0;
  }
}