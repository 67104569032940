@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./Pages/assets/fonts/Raleway/Raleway-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}


* {
 
  font-family: 'Raleway';
 
 
}

.App {
  text-align: center;
}

.container {
 
  margin: 0 auto;
  padding: 0 0px;
 
  overflow-x: hidden !important;
}

@media only screen and (min-width: 2560px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  scrollbar-width: thin;
  scrollbar-color: black transparent; 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}