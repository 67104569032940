body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: Arial, sans-serif;
}

#discIDcursor {}

.HomePage {
  background-color: black;
  overflow-x: hidden;

}

#LandingPage {
  height: 130vh;
  background-color: #d4cac0;
  text-align: center;
  overflow: hidden;
  z-index: 9;
  padding-bottom: 10vh;
  overflow-x: hidden;
}

#LandingPage img {
  width: 100%;
  height: 130vh;
  object-fit: cover;
}

#LandingPage #LogoStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140vh;
  background-color: black;
  pointer-events: none;
  z-index: 0;
}

#LandAboutWrapper {
  width: 100%;
  background-color: black;
  padding: 0px 0;
}

#LandingAbout {
  padding: 5% 2.5% !important;
  margin: 0;
  width: 85%;
  color: white;
  position: relative;
  z-index: 0;
  transition: opacity 0.5s ease-in;
}

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

#LandingAbout h4 {
  font-size: 56px;
  font-weight: 500;
  line-height: 1.25;
  padding-left: 2.5%;
  padding-top: 5%;
  font-family: "Oswald", sans-serif;
}

.bold-text {
  font-weight: bold;
}

.content {
  max-width: 600px;
  margin: 20px;
}

.gradient-text {
  background: -webkit-linear-gradient(90deg, #B58CF7, #9D89D9, #7591C6, #8A998B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5em;
  margin: 20px 0;
}

#AboutTextWrap {
  font-size: 8.25svh !important;
  line-height: 8.25svh;
  letter-spacing: -1px;
  line-height: 7.5svh !important;
  font-weight: 400 !important;
  
}

#WidthTextWrap{
  width: 80%;
}

.linear-one {
  background: linear-gradient(0deg, rgba(183, 232, 207, 1) 0%, rgba(117, 208, 204, 1) 1%, rgba(206, 98, 237, 1) 1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.linear-two {
  background: linear-gradient(0deg, rgba(183, 232, 207, 1) 0%, rgba(206, 98, 237, .8) 1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.linear-three {
  background: linear-gradient(180deg, #A09ADC 20%, #A2A0DB 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.linear-four {
  background: linear-gradient(180deg, #89B8D4 20%, #8AB7D4 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


.linear-five {
  background: linear-gradient(180deg, #78D0CD 20%, #75D4CB 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.linear-six {
  background: linear-gradient(180deg, #AAEBD6 20%, #A6ECD8 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.linear-seven {
  background: linear-gradient(180deg, #A9EBD7 20%, #AAECD7 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.linear-eight {
  background: linear-gradient(180deg, #E1E2BC 20%, #E1E2BC 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


.linear-two-gold {
  background: #E6D3AE;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.linear-gradient-text {
  background: linear-gradient(90deg, #d88bff, #89dbff, #c8ffcb, #f7e389);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.ContactUs {
  background-color: white;
  padding: 60px;
  display: flex;
  flex-direction: column;
  padding-top: 1vh;
}

.ContactUs #SubmitForm {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.ContactUs #SubmitForm input {
  padding: 15px;
  border-radius: 20px;
  font-size: 24px;
  width: 500px;
  margin-bottom: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
}


#SendButton {
  width: 35%;
  align-self: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 20px;
  background-color: black;
  color: white;
  font-size: 18px;
  font-weight: bold;
  max-width: 500px;
}

#SendButton:hover {
  background-color: white;
  color: black;
  transition: .4s;
  cursor: pointer;
  scale: 1.05;
  transition: .5s ease-out;
}

.ContactUs #TitleContact h3 {
  font-size: 64px;
  font-weight: bolder;
  margin: 0;
  letter-spacing: -3px;
  -webkit-text-stroke: 3px black;
}

.ContactUs #TitleContact h4 {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;
  letter-spacing: -3px;
}

.DiscDisplay {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 5%;
  padding-bottom: 0%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 50%;
  /* background: linear-gradient(to bottom, rgb(0, 0, 0) 100vh, rgba(255, 255, 255, 1) 10vh); */
  background-color: black;
}

.DiscDisplay::-webkit-scrollbar {
  display: none;
}

.CDWrapper {
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin: 7.5%;
  /* margin-left: 20%; */
  /* margin-right: 10%; */
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  margin-right: 50%;
}

.CDWrapper h4 {
  color: white;
  font-size: 64px;
  text-decoration: none;
  padding: 2.5%;
  font-weight: 700;
  -webkit-text-stroke: 4px white;
  letter-spacing: 4px;

}

#swipeText {
  animation: FadeSwipe 4s infinite;

}

@keyframes FadeSwipe {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.70;
  }

  100% {
    opacity: 0.00;
    display: none;
  }
}

@keyframes textAnimation {
  0% {
    -webkit-text-stroke: 1px rgba(0, 255, 170, 0.25);
    color: #fff;
    text-shadow:
      0 0 1px #0fa 0 0 3px #0fa 0 0 8px #0fa 0 0 15px #0fa 0 0 22px #0fa 0 0 30px #0fa;
    opacity: 1
  }

  17% {
    -webkit-text-stroke: 1px rgba(0, 255, 170, 0.5);
    color: #fff;
    text-shadow:
      0 0 1px #0fa 0 0 3px #0fa 0 0 8px #0fa 0 0 15px #0fa 0 0 22px #0fa 0 0 30px #0fa;
    opacity: 1
  }

  75% {
    -webkit-text-stroke: 2px white;
    opacity: .25;
  }

  100% {
    -webkit-text-stroke: 1px rgba(0, 255, 170, 0.25);
    color: #fff;
    text-shadow:
      0 0 1px #0fa 0 0 3px #0fa 0 0 8px #0fa 0 0 15px #0fa 0 0 22px #0fa 0 0 30px #0fa;
    opacity: 1
  }
}

.CDWrapper #cdWrapText {
  color: white;
  font-size: 7.5svh;
  text-decoration: none;
  padding: 2.5%;
  -webkit-text-stroke: 4px white;
  letter-spacing: 0px;
  opacity: 1;
  animation: textAnimation 5s infinite;
  margin-left: 15%;
  margin-right: 10%;
}



.CDWrapper img {
  height: 132.5%;
  object-fit: contain;

}

#WhereWeAt {
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
  padding-bottom: 2.5vh;
  transition: background 0.5s ease;
}

#WhereWeAt #WhereText {
  font-size: 64px;
  font-weight: 800;
  -webkit-text-stroke: 1px black;
  font-style: oblique;
  letter-spacing: -1px;
}

#WhereWeAt #LocationText {
  font-size: 32px;
  font-weight: 400;
  position: absolute;
  color: black;
  left: 30%;
  margin-top: 25%;
}

#WhereWeAt #Location {
  width: 90vh;
  height: 90vh;
  border-radius: 45vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

#WhereWeAt #Location img {
  width: 80%;
  height: 80%;
}

#HomeAbout {
  border-style: solid;
  border-radius: 30px;
  border-color: rgb(23, 243, 210);
  width: 10%;
  padding: 20px;
  text-align: center;
  margin-bottom: 2.5%;
  cursor: pointer;
  background-color: transparent;
  margin: 0;
}

#LogoStyle {
  width: 10svh !important;
}

.loading-indicator {
  position: fixed; /* Fix it in the center of the viewport */
  top: 50%;        /* Center vertically */
  left: 50%;       /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset by half of its width and height */
  padding: 20px;   /* Add some padding */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white;    /* Text color */
  border-radius: 8px; /* Rounded corners */
  font-size: 18px;  /* Font size */
  z-index: 999;     /* Ensure it appears above other elements */
  display: flex;    /* Use flexbox for centering text */
  align-items: center;
  justify-content: center;
}

/* Optional: Add a spinner */
.loading-indicator::after {
  content: ''; /* Empty content for the spinner */
  border: 4px solid rgba(255, 255, 255, 0.3); /* Spinner color */
  border-top: 4px solid white; /* Top color for the spinner */
  border-radius: 50%; /* Make it circular */
  width: 24px; /* Spinner width */
  height: 24px; /* Spinner height */
  animation: spin 1s linear infinite; /* Spin animation */
  margin-left: 10px; /* Space between text and spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media only screen and (max-width: 768px) {
  #HomeAbout {
    margin-top: 50%;
  }
}

#HomeAbout:hover {
  background-color: rgb(23, 243, 210);
  transition: .7s;
}

#HomeAbout a {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

@media only screen and (min-width: 1513px) {
  #AboutTextWrap {
    /* font-size: 6svh !important;
    line-height: 5.85svh !important; */
  }
}

@media only screen and (min-width: 1920px) {
  #LandingPage img {
    width: 90%;
    object-fit: cover;
  }

  #WhereWeAt #WhereText {
    font-size: 7.5svh;
  }

}

@media only screen and (min-width: 2560px) {
  #LandingPage img {
    width: 66.67%;
    object-fit: cover;
  }

  #LandingAbout h4 {
    padding-left: 6.25% !important;
  }

  .DiscDisplay {
    padding-left: 12.5%;
  }
}
@media only screen and (max-width: 1506px) {
  #AboutTextWrap {
    font-size: 7.5svh !important;
    line-height: 7.5svh !important;

  }
}


@media only screen and (max-width: 1024px) {
  #AboutTextWrap {
    font-size: 4.25svh !important;
    line-height: 4.25svh !important;

  }

  #LandAboutWrapper #HomeAbout {
    width: 30%;
  }

  .CDWrapper img {
    height: 100%;
    object-fit: contain;
    margin-right: 10%;
  }

  .CDWrapper #cdWrapText {
    font-size: 5vh;
  }
}

@media only screen and (max-width: 850px) {
  #AboutTextWrap {
    font-size: 3.5svh !important;
    line-height: 4.1svh !important;

  }
  #WhereWeAt #WhereText {
    font-size: 4.25svh;
  }
}


@media only screen and (max-width: 768px) {
  .mobile-bg {
    background-image: url('../assets/new-bg-tall.png');
    background-size:cover;
    background-position: 45% 50%;
    width: 100%;
    height: 100svh;
  }
  
  .CDWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .CDWrapper img {
    max-width: 80%;
    max-height: 80%;
    width: 70%;
    object-fit: contain;
    margin-right: 0%;
  }

  .CDWrapper #cdWrapText {
    color: white;
    font-size: 3.5vh;
    text-decoration: none;
    padding: 0;
    -webkit-text-stroke: 1px white;
    letter-spacing: 4px;
    opacity: 1;
    animation: none;
  }

  #swipeText img {
    width: 10% !important;
  }

  .DiscDisplay {
    padding-bottom: 5vh;
  }

  #LandingPage {
    height: 100vh;
    background-color: #d5d5d5;
    text-align: center;
    overflow: hidden;
    z-index: 9;
  }

  #LandingAbout h4 {
    font-size: 32px;
  }

  #LandingAbout {
    width: 100% !important;
  }

  .ContactUs {
    padding-left: 0;
    padding-right: 0;
    display: block;
  }

  .ContactUs #SubmitForm input {
    width: 90%;
    font-size: 18px;
  }

  .ContactUs #SubmitForm input {
    padding: 3vh;
    border-radius: 20px;
    font-size: 24px;
    width: 500px;
    margin-bottom: 10px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
  }

  #SendButton {
    width: 50%;
  }

  #WhereWeAt #WhereText {
    font-size: 32px;
  }

  #WhereWeAt #LocationText {
    font-size: 18px;
    left: 20%;
    margin-top: 40%;
  }

  #WhereWeAt #Location {
    width: 60vh;
    height: 60vh;
  }

  #WhereWeAt #Location img {
    width: 80%;
    height: 80%;
  }

  #LandAboutWrapper #HomeAbout {
    width: 30%;
    padding-left: .5%;
    padding-right: .5%;
    padding-top: 4%;
    padding-bottom: 4%;
  }

  .ContactUs #TitleContact h3 {
    font-size: 7vh;
    letter-spacing: -3px;
    -webkit-text-stroke: .1vh black;
  }

  #AboutTextWrap {
    width: 90% !important;
    font-size: 3.5svh !important;
    margin-top: -50% !important;

  }

  .ContactUs #TitleContact h4 {
    font-size: 5vh;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
    letter-spacing: 0;
  }

  #WhereWeAt {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #GlobeWrapper {
    width: 100% !important;
  }

  #WhereWeAt #LocationText {
    color: white;
    left: 20% !important;
  }
}

@media only screen and (max-width: 720px) {
  #LandAboutWrapper #HomeAbout {
    width: 50%;
  }

  #LandingAbout h4 {
    font-size: 24px;
  }

  #LandAboutWrapper {
    width: 100%;
    background-color: black;
    padding: 30px 0;
  }

  #LandingAbout {
    width: 100% !important;
  }


  .ContactUs #SubmitForm input {
    font-size: 16px;
  }

  #SendButton {
    width: 60%;
    border-radius: 10px;

  }

  #WhereWeAt #WhereText {
    font-size: 24px;
    letter-spacing: 0px;
    -webkit-text-stroke: 1px black;
  }

  #WhereWeAt #LocationText {
    font-size: 14px;
    left: 10%;
    margin-top: 50%;

  }

  #WhereWeAt #Location {
    width: 40vh;
    height: 40vh;
  }

  #WhereWeAt #Location img {
    width: 80%;
    height: 80%;
  }

  .ContactUs #TitleContact h3 {
    font-size: 4vh;
    letter-spacing: -3px;
    -webkit-text-stroke: .1vh black;
    padding: 0;
    margin: 0;
  }

  .ContactUs #TitleContact h4 {
    font-size: 3vh;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
    letter-spacing: 0;
  }

  .ContactUs #SubmitForm input {
    width: 75%;
    padding: 3vh;

  }

  #AboutTextWrap {
    width: 100% !important;
    font-size: 7.25svw !important;
  }

}